import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import Footer from "components/Footer";
import LandingPageNavBar from "components/LandingPageNavBar";
import { AuthAction, useAuthUser, withAuthUser } from "next-firebase-auth";
import Head from "next/head";
import React from "react";

function ForGyms() {
  const authUser = useAuthUser();

  return (
    <>
      <Head>
        <title>Indoor Ascents</title>
        <meta
          name="description"
          content="Indoor Ascents, the premire rock climbing training app"
        />
        <meta property="og:title" content="Indoor Ascents" />
        <meta
          property="og:description"
          content="The premire rock climbing training app"
        />
        <meta property="og:url" content="https://indoorascents.com/" />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-col h-screen">
        <LandingPageNavBar />
        <div className="flex w-full h-full items-center justify-center">
          <a className="text-5xl">404 - Page Not Found</a>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default withAuthUser({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.RENDER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  appPageURL: "/loginRedirect",
})(ForGyms);
